import React from 'react'

const Plans = () => {
  return (
  <div>
    <div className="w-full h-full bg-padrao font-inter p-10">
      <div className="container px-4 mx-auto">
        <div>
          <div className="text-center my-5" id="plans">
            <h1 className="font-bold text-4xl">Conheça nossos planos</h1>
            <p className="text-light text-xl">
              Escolha o plano que combina com você!
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-evenly gap-5 pt-10">
            <div className="rounded-lg text-center bg-white overflow-hidden w-full transform hover:shadow-2xl hover:scale-105 transition duration-200 ease-in shadow shadow-lg">
              <div className="w-full">
                <h2 className="font-bold text-3xl bg-[#00A5FF] text-white p-3 w-full rounded-t-xl">Individual</h2>
              </div>
              <div id="content">
                <div id="icon" className="my-5">
                  <p><span className='font-bold text-2xl'>7,00 R$</span> / unid</p>
                  <p className="text-gray-500 text-sm pt-2">
                    Envie documentos por unidade
                  </p>
                  <div className='mt-4'>
                    <a href='https://app.pluralsign.com.br/users/sign_in' className='bg-orange-500 text-white w-1/2 py-1.5 px-2 rounded-lg hover:bg-orange-400 font-bold'>Assinar</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg text-center bg-white overflow-hidden w-full transform hover:shadow-2xl hover:scale-105 transition duration-200 ease-in shadow shadow-lg">
              <div className="w-full">
                <h2 className="font-bold text-3xl bg-[#00A5FF] text-white p-3 w-full rounded-t-xl">Básico</h2>
              </div>
              <div id="content">
                <div id="icon" className="my-5">
                  <p><span className='font-bold text-2xl'>36,00 R$</span> / mês</p>
                  <p className="text-gray-500 text-sm pt-2">
                    Envie até 20 documentos mensais
                  </p>
                  <div className='mt-4'>
                    <a href='https://app.pluralsign.com.br/users/sign_in' className='bg-orange-500 text-white w-1/2 py-1.5 px-2 rounded-lg hover:bg-orange-400 font-bold'>Assinar</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg text-center bg-white overflow-hidden w-full transform hover:shadow-2xl hover:scale-105 transition duration-200 ease-in shadow shadow-lg">
              <div className="w-full">
                <h2 className="font-bold text-3xl bg-[#00A5FF] text-white p-3 w-full rounded-t-xl">Profissional</h2>
              </div>
              <div id="content">
                <div id="icon" className="my-5">
                  <p><span className='font-bold text-2xl'>77,00 R$</span> / mês</p>
                  <p className="text-gray-500 text-sm pt-2">
                    Envie até 70 documentos mensais
                  </p>
                  <div className='mt-4'>
                    <a href='https://app.pluralsign.com.br/users/sign_in' className='bg-orange-500 text-white w-1/2 py-1.5 px-2 rounded-lg hover:bg-orange-400 font-bold'>Assinar</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg text-center bg-white overflow-hidden w-full transform hover:shadow-2xl hover:scale-105 transition duration-200 ease-in shadow shadow-lg">
              <div className="w-full">
                <h2 className="font-bold text-3xl bg-[#00A5FF] text-white p-3 w-full rounded-t-xl">Corporativo</h2>
              </div>
              <div id="content">
                <div id="icon" className="my-5">
                  <p><span className='font-bold text-2xl'>147,00 R$</span> / mês</p>
                  <p className="text-gray-500 text-sm pt-2">
                    Envie até 150 documentos mensais
                  </p>
                  <div className='mt-4'>
                    <a href='https://app.pluralsign.com.br/users/sign_in' className='bg-orange-500 text-white w-1/2 py-1.5 px-2 rounded-lg hover:bg-orange-400 font-bold'>Assinar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='text-center space-y-6 mb-6'>
      <div>
        <h1 class="font-semibold text-2xl">Precisa de um volume maior de documentos?</h1>
        <p class="text-xl">Clique no botão para falar com a gente e personalize seu plano do seu jeito!</p>
      </div>
      <div>
        <a className='bg-[#00A5FF] text-white md:px-2 md:py-3 py-1 px-2 rounded-xl text-xl md:text-2xl font-semibold uppercase hover:opacity-90' href='https://wa.me/5516994551163' target="_blank">+ Personalize o seu plano</a>
      </div>
    </div>
  </div>
  )
}

export default Plans
