import React from 'react'
import NavBar from '../components/NavBar'
import Hero from './Hero'
import Benefits from './Benefits'
import World from './World'
import Costs from './Costs'
import Plans from './Plans'
import Cookies from './api/Cookies'
import Footer from './Footer'
import Partners from './Partners'
const Home = () => {
  return (
    <div>
      <NavBar/>
      <div className='px-16'>  
        <Hero/>
      </div>
      <Partners/>
      <div className='px-4 md:px-16'>
        <Benefits/>
      </div>
      <World/>
      <Costs/>
      <Plans/>
      <Footer/>

      <Cookies/>
    </div>
  )
}

export default Home
