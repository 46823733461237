export default function Questions() {
  return (
    <div id="duvidas" className="md:flex md:items-center justify-center mb-16 p-2 w-full text-center md:text-start">
      <div className="md:w-[600px] flex flex-col space-y-6">
        <h1 className="text-2xl md:text-4xl font-semibold">
          Dúvidas se nossa plataforma é a solução ideal para sua instituição?
        </h1>
        <p className="text-lg md:text-3xl">
          Fale com a nossa equipe através do
          WhatsApp. Teremos o maior prazer em responder suas dúvidas!
        </p>
        <a className="md:w-1/2 text-white text-center text-semibold uppercase bg-[#00A3FF] hover:bg-[#048ad5] rounded-lg px-5 py-2.5 mr-2 mb-2 focus:outline-none"
             href="https://wa.me/5516994551163" target="_blank" rel="noreferrer">
            fale conosco
        </a>
      </div>
      <div>
        <img src="/questions.png" alt="questions" />
      </div>
    </div>
  )
}
