import React from 'react'

const Costs = () => {
  return (
    <div>
      <div className='p-2 mt-24'>
        <div className='text-center text-[#06004A] mb-8'>
          <h1 className='text-4xl font-semibold mb-1'>Vantagens de assinar com a gente</h1>
          <h2 className='text-2xl'>Veja os custos que você evita ao assinar com a gente!</h2>
        </div>
        <div className='grid md:flex justify-center md:space-x-6 items-center space-y-6'>
          <div className='items-center'>
            <p className='bg-[#06004A] text-white uppercase text-xl rounded-xl px-2 py-1 w-[200px] text-center mt-0.5'>Custos</p>
            <div className='flex-col space-y-2.5 mt-5 justify-center text-center text-2xl'>
              <p className='md:mt-[-15px]'>Papel</p>
              <p>Impressão</p>
              <p>Armazenamento</p>
              <p>Mão de obra</p>
              <p>Autenticações</p>
            </div>
          </div>
          <div>
            <p className='bg-[#06004A] text-white uppercase text-xl rounded-xl px-2 py-1 w-[200px] text-center'>Com pluralsign</p>
            <div className='flex-col space-y-5 mt-5'>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
              </div>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
              </div>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
              </div>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
              </div>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <p className='bg-[#06004A] text-white uppercase text-xl rounded-xl px-2 py-1 w-[200px] text-center'>Convencional</p>
            <div className='flex-col space-y-5 mt-5'>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </div>
              <div className='flex justify-center'>
              <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </div>
              <div className='flex justify-center'>
              <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </div>
              <div className='flex justify-center'>
              <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </div>
              <div className='flex justify-center'>
                <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-center mt-6 px-12'>
        <hr className='w-full'/>
      </div>
    </div>
  )
}

export default Costs
