import Home from "./pages/Home";

function Site() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default Site;
