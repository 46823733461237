import Benefits from './pages/api/Benefits';
import Home from './pages/api/Home';
import Questions from './pages/api/Questions';
import Cookies from './pages/api/Cookies';
import Nav from './components/Nav';
import Footer from './pages/Footer';

function App() {
  return (
    <div className="container mx-auto">
      <Nav/>
      <Home />
      <Benefits />
      <Questions />
      <Cookies/>
      <Footer/>
    </div>
  );
}

export default App;
