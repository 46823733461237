import React from 'react'

const Benefits = () => {
  return (
    <div className='bg-gray-100 mb-12 py-12 shadow-xl rounded-lg mt-12'>
      <div className="grid md:flex justify-center gap-y-12 gap-x-12">
        <div className="w-[300px] flex flex-col items-center justify-top px-2">
          <img src="cadeado.svg" alt="lock" className="h-[120px] shadow-2xl p-4 rounded-full mb-12 bg-white" />
          <p className='text-center font-bold mb-2'>Segurança</p>
          <p className="text-center">
            Nossa aplicação foi projetada para trazer
            simplicidade e acessibilidade ao
            processo de assinatura de documentos
            sem comprometer a segurança.
          </p>
        </div>
        <div className="w-[300px] flex flex-col items-center justify-top">
          <div className='shadow-2xl p-4 justify-center flex rounded-full bg-white mb-12'>
            <img src="planeta-terra.svg" alt="bolt" className="h-[90px]" />
          </div>
          <p className='text-center font-bold mb-2'>Sustentabilidade</p>
          <p className="text-center">
          É mais ecológica do que a assinatura fisica! A assinatura digital elimina a necessidade de
          impressão de documentos, reduzindo assim o consumo de papel e a poluição.
          </p>
        </div>
        <div className="w-[300px] flex flex-col items-center justify-top">
          <img src="estrela.svg" alt="check" className="h-[120px] shadow-2xl p-4 rounded-full mb-12 bg-white" />
          <p className='text-center font-bold mb-2'>Facilidade</p>
          <p className="text-center">
          Assine documentos digitalmente em apenas alguns cliques.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Benefits
