import React from 'react'

const Hero = () => {
  return (
  <div id="hero" className='md:flex items-center justify-center md:space-x-[200px] mt-12'>
    <div className='2xl:w-1/5 lg:w-1/3 text-center md:text-start'>
      <h1 className='2xl:text-5xl lg:text-5xl md:text-3xl text-3xl font-bold'>Assine documentos com facilidade e segurança!</h1>
      <div className='mt-12'>
        <a href="#about" className="bg-[#00A5FF] rounded-lg py-1.5 text-white font-bold lg:text-2xl md:text-xl 2xl:text-3xl uppercase px-12">Saiba mais</a>
      </div>
    </div>
    <div>
      <img src='image_1.png' className='mt-12 md:mt-0'></img>
    </div>
  </div>
  )
}

export default Hero
