import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="bg-white rounded-lg shadow">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <div class="sm:flex sm:items-center sm:justify-between">
              <a href="#" className="flex items-center mb-4 sm:mb-0">
                <img src="logo_plural.png" className="h-8 mr-3" alt="Pluralsign Logo" />
              </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0">
            <li>
                <a href="#sobre" className="mr-4 hover:underline md:mr-6 ">Sobre</a>
            </li>
            <li>
                <a href="https://wa.me/5516994551163" target='_blank' className="mr-4 hover:underline md:mr-6">Contato</a>
            </li>
            <li>
                <a href='PluralSign - POLÍTICAS DE PRIVACIDADE E DE PROTEÇÃO DE DADOS PESSOAIS.pdf' target="_blank" className="mr-4 hover:underline md:mr-6">Politicas de Privacidade</a>
            </li>
            <li className='flex'>
              <a href="https://www.instagram.com/pluralsign/" target="_blank" className="hover:underline md:mr-6">
                <img src="insta.png" className='w-[15px]' />
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="#" className="hover:underline">PluralSign</a>. Direitos Reservados</span>
      </div>
    </footer>
    </div>
  )
}

export default Footer
