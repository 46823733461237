import CookieConsent from 'react-cookie-consent'

export default function Cookies() {
  return (
    <div>
      <CookieConsent
        style={{background: "white", alignItems: "center"}}
        buttonText="Aceitar"
        buttonStyle={{background: "#00A5FF", color: "white", borderRadius: "9px", padding: "8px", fontSize: "16px" }}
        expires={360}
      >
        <div className='flex space-x-4 items-center'>
          <div>
          <img src="cookie.png" alt="" className='w-[50px]' />
          </div>
          <div>
            <p className='mb-1 text-base font-semibold text-gray-900'>Utilizamos Cookies</p>
            <p className='text-sm font-normal text-gray-500'>
              Nosso site utiliza cookies para melhorar sua experiência. Ao continuar navegando, você concorda com o uso de cookies de acordo com nossos termos de<a href='PluralSign - POLÍTICAS DE PRIVACIDADE E DE PROTEÇÃO DE DADOS PESSOAIS.pdf' target='_blank' rel="noreferrer" className='hover:underline font-semibold hover:text-orange-400'> Politicas de Privacidade</a> .
            </p>
          </div>
        </div>
      </CookieConsent>
    </div>
  )
}
