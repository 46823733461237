import React from 'react'

const World = () => {
  return (
    <div id='about' className='md:mx-36 2xl:mx-[400px]'>
      <div className='grid grid-col-1 md:grid-cols-2 items-center'>
        <div>
          <img src='world.png' className='w-[400px] md:w-[600px]'></img>
        </div>
        <div className='md:flex justify-center'>
          <div className='p-5 md:w-2/3 '>
            <h1 className='text-5xl text-center md:text-start font-bold'>Assine os seus documentos de qualquer lugar do mundo!</h1>
            <p className='text-5xl text-center text-center md:text-start uppercase font-bold text-gray-400 mt-12 md:ml-16'>+20.000</p>
            <div className='text-center'>
              <p className='md:w-2/3 text-xl text-center text-gray-400 font-semibold'>Documentos assinados com o PluralSign</p> 
            </div>
          </div>
        </div>  
      </div>
    </div>

  )
}

export default World
