import React, { useRef, useState } from 'react'

const Nav = () => {
  const dropDownRef = useRef(null);
  const [isActive, setIsActive] = useState(true)
  const onClick = () => setIsActive(!isActive)

  return (
    <div>
      <nav className="bg-white border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-12 lg:mx-auto p-4">
          <a href="/" className="flex items-center">
          <img src="logo_plural.png" className="h-[50px] mt-2 mr-3" alt="PluralSign Logo" />
          </a>
          <div className='menu-container'>
            <button onClick={onClick} type="button" className="menu-button">
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>

            <div ref={dropDownRef}
                className={`menu ${isActive ? "active" : "inactive"}`}>
               <ul class="py-2 text-sm text-gray-700">
                  <li>
                    <a href="/" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">Inicio</a>
                  </li>
                  <li>
                    <a href="#beneficios" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">Benefícios</a>
                  </li>
                  <li>
                    <a href="#duvidas" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">Dúvidas</a>
                  </li>
                  <li>
                    <a href="https://wa.me/5516994551163" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">Contato</a>
                  </li>
                </ul>
            </div>
          </div>

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col items-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-4 md:mt-0 md:border-0 md:bg-white">
              <li>
                <a href="/" className="block py-2 pl-3 pr-4 bg-blue-700 text-gray-900 hover:text-blue-700 rounded md:bg-transparent md:p-0" aria-current="page">Inicio</a>
              </li>
              <p>|</p>
              <li>
                <a href="#beneficios" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Benefícios</a>
              </li>
              <p>|</p>
              <li>
                <a href="#duvidas" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 ">Dúvidas</a>
              </li>
              <p>|</p>
              <li>
                <a href="https://wa.me/5516994551163" target='_blank' className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Contato</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Nav
