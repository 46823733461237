export default function Home() {
  return (
    <div className="bg-cover bg-[url('background.png')]">
      <div className="grid items-center lg:grid-cols-2 lg:gap-x-12 px-6 mx-auto border-b mt-12 text-center md:text-start">
        <div className="w-auto">
          <img src="logo_api.png" alt="logo_api" className="mx-auto md:mx-0 md:h-12 h-[30px]" />
          <p className="text-md md:text-xl lg:text-3xl my-6">
            Transforme a emissão de diplomas da sua instituição
            com facilidade e segurança!
          </p>
          <a className="text-white text-semibold uppercase bg-[#00A3FF] hover:bg-[#048ad5] rounded-lg px-5 py-2.5 mr-2 mb-2 focus:outline-none"
             href="https://wa.me/5516994551163" target="_blank" rel="noreferrer">
            saiba mais
          </a>
        </div>
        <img src="imagem.png" alt="imagem" className="xl:h-[600px] md:h-[500px] h-[300px]" />
      </div>
      <div id="sobre" className="ml-9 md:flex md:justify-between">
        <div className="w-[300px] flex flex-col items-center justify-top px-2">
          <img src="lock.png" alt="lock" className="h-24 md:h-36" />
          <p className="text-center">
            Nossa API foi projetada para trazer
            simplicidade e acessibilidade ao
            processo de emissão de diplomas
            sem comprometer a segurança.
          </p>
        </div>
        <div className="w-[300px] flex flex-col items-center justify-top">
          <img src="bolt.png" alt="bolt" className="h-24 md:h-36" />
          <p className="text-center">
          Com uma integração fácil de implementar, as instituições podem emitir diplomas
          diretamente de seus sistemas de forma intuitiva e eficaz, eliminando complicações
          desnecessárias.
          </p>
        </div>
        <div className="w-[300px] flex flex-col items-center justify-top">
          <img src="check.png" alt="check" className="h-24 md:h-36" />
          <p className="text-center">
          A PluralAPI é a solução ideal para instituições que buscam otimizar seus processos e
          oferecer uma experiência de qualidade aos seus alunos.
          </p>
        </div>
      </div>
    </div>
  )
}
