export default function Benefits() {
  return (
    <div id="beneficios" className="py-8 mb-8 md:space-y-6 border-b px-2">
      <h1 className="text-[#FF6B00] text-2xl md:text-4xl font-semibold text-center">
        Principais recursos e benefícios
      </h1>
      <div>
        <div className="md:flex mx-auto w-full md:justify-center p-2 text-center">
          <div className="flex justify-center">
            <img src="rocket.png" alt="rocket" className="w-24 md:w-32" />
          </div>
          <div className="text-center md:text-start md:w-[600px]">
            <h1 className="text-xl font-semibold">Agilidade e eficiência</h1>
            <p className="text-justify">
              Destrave os processos manuais e demorados. Nossa API permite a configuração de fluxos de trabalho inteligentes, permitindo tanto
              a intervenção humana quanto a automação.
            </p>
          </div>
        </div>

        <div className="md:flex mx-auto w-full md:justify-center p-2 text-center">
          <div className="flex justify-center">
            <img src="security.png" alt="security" className="w-24 md:w-32" />
          </div>
          <div className="text-center md:text-start md:w-[600px]">
            <h1 className="text-xl font-semibold">Assinaturas digitais seguras</h1>
            <p className="text-justify">
              As assinaturas são validadas por certificação digital e carimbo do tempo, cumprindo os requisitos estabelecidos pelo Padrão
              Brasileiro de Assinaturas Digitais na Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil).
            </p>
          </div>
        </div>

        <div className="md:flex mx-auto w-full md:justify-center p-2 text-center">
          <div className="flex justify-center">
            <img src="code.png" alt="code" className="w-24 md:w-32" />
          </div>
          <div className="text-center md:text-start md:w-[600px]">
            <h1 className="text-xl font-semibold">Integração simplificada</h1>
            <p className="text-justify">
              A API pode ser facilmente integrada aos sistemas de
              gerênciamento acadêmico existentes minimizando a necessidade de treinamento adicional.
            </p>
          </div>
        </div>

        <div className="md:flex mx-auto w-full md:justify-center p-2 text-center md:items-center">
          <div className="flex justify-center">
            <img src="cloud.png" alt="cloud" className="w-24 md:h-[130px] md:w-32" />
          </div>
          <div className="text-center md:text-start md:w-[600px]">
            <h1 className="text-xl font-semibold">Armazenamento em nuvem</h1>
            <p className="text-justify">
              Os arquivos digitais são armazenados de forma segura na nuvem durante todo o processo de emissão,
              garantindo a integridade e a disponibilidade dos documentos. Após a emissão, os arquivos serão armazenados
              em um repositório de longo prazo por parte da instituição e serão eliminados a fim de mitigar qualquer possibilidade de vazamento de dados.
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}
