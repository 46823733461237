import React from 'react'

const Partners = () => {
  return (
    <div>
       <div className='flex justify-center mt-6 px-12'>
      <hr className='w-full'/>
    </div>
    <div className='grid md:flex items-center justify-center px-12 mt-12'>
      <div className='text-center'>
        <h1 className='text-slate-600 uppercase font-semibold'>Clientes que confiam no nosso produto</h1>
        <div className='flex items-center justify-center'>
          <div>
            <img src="logo_doctum.png" className='w-[230px]' />
          </div>
          <div>
            <img src="logo_pp.png" className='w-[240px] mb-2' />
          </div>
        </div>
      </div>
    </div>
    <div className='flex justify-center mt-6 px-12'>
      <hr className='w-full'/>
    </div>
  </div>
  )
}

export default Partners
